// $primary: rgb(128, 0, 219);

@import 'bootstrap/scss/bootstrap.scss';

#logo {
max-width: calc(min(25%,250px));
top: 5px;
left: 5px;
position: absolute;
}

.fa {
    transition: .3s transform ease-in-out;
  }

.collapsed .fa {
  transform: rotate(-90deg);  
}

.cta {
margin: 3em;
padding: 5px;
background-color: hsl(208, 100%, 90%);
font-size:larger;
border-radius: 10px;
}
.cta li {
  padding: 5px;
}

#gallery > div{
display: flex;
flex-wrap: wrap;
justify-content: space-evenly;
gap: 10px;
margin: 1em;
}

.gallery-item {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // width: 400px;
  height: 250px;
  padding: 10px;
  // margin: 10px;
  border: 1px gray solid;
  border-radius: 5px;

}
.gallery-item a{
  height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.gallery-item img {
  align-self: center;
  height: 100%;
  width: 100%;
  // vertical-align: middle;

  // height: auto;
  object-fit: contain;
}




.header{
  background-image: url(../img/hero.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */ 
}

.header .headline {
  // position: relative;
  // bottom: -2em;
}

body{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
footer{
  margin-top: auto;
}
footer ul{
  display: flex;
  justify-content: center;
  margin: 0.5em;
}
footer li{
  list-style: none;
  margin-left: 1em;
  margin-right: 1em;
}

.footer {
  /* position:relative; */
  position: absolute;
  margin-top: auto;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: black;
  text-align: center;
  padding-bottom: 0.5em;
  bottom: 0;
}
.footer ul{
  display: flex;
align-items: center;
}

// .footer a{
//   color: black;
//   margin-left: 1em;
//   margin-right: 1em;
// }

table.notable {
margin-left: auto;
margin-right: auto; 
 }

table.notable th,td {
padding: 5px;
}

table.notable tr {
border-bottom: 1px black solid;  
}